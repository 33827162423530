// @flow
import style from "./style.module.scss";

import React from "react";
import { Typography } from "@2po-dgp/components";
import BGLogo from "../../images/simple-logo.inline.svg";

const NotFoundPage = () => (
    <div className={style.notFound}>
        <div className={style.content}>
            <div className={style.fourOFour}>
                <Typography
                    type="heading-1-article"
                    additionalClass={style.smallLabel}
                >
                    {"Page not found"}
                </Typography>
                <Typography
                    type="heading-1"
                    color="primary"
                    additionalClass={style.bigLabel}
                >
                    {"404"}
                </Typography>
            </div>
            <div className={style.copy}>
                <Typography type="bodyCopy">
                    {
                        "We are experts in private banking, and we are here to help you. However, the page you were looking for is not available. It might be an old link, the page moved, or the web address is not correct. You can use our search button, contact support or navigate back to our Blog. We are looking forward to assisting you soon."
                    }
                </Typography>
            </div>
        </div>
        <BGLogo className={style.logo} />
    </div>
);

export default NotFoundPage;
